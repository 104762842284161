
// @ts-nocheck
import LayoutFooter from '~/components/layout/LayoutFooter.vue'
import LayoutHeader from '~/components/layout/LayoutHeader.vue'
import MainPageContent from '~/components/new/main-page/MainPageContent.vue'
import PaintingsBlock from '~/components/new/main-page/PaintingsBlock.vue'
import { useModalStore } from '~/store/modals'
const modalStore = useModalStore()

export default {
  components: { LayoutHeader, LayoutFooter, PaintingsBlock, MainPageContent },
  layout: 'empty',
  data () {
    return {
      displayGallery: !this.$route.query.page
    }
  },
  head () {
    return {
      title: 'Arthall online',
      meta: [
        // { hid: 'description', name: 'description', content: this.$t('head.description.index') },
        // { hid: 'og:title', name: 'og:title', content: this.$t('head.title.index') },
        // { hid: 'og:description', name: 'og:description', content: this.$t('head.description.index') },
        // { hid: 'og:image', name: 'og:image', content: require('~/assets/img/logo-black.svg') },
        // { hid: 'og:type', name: 'og:type', content: 'website' },
        // { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  },
  watch: {
    displayGallery (value, old) {
      if (value === old) { return }

      this.$router.replace({ query: { page: value ? undefined : 'true' } })
    }
  },
  async mounted () {
    const paintingsEl: HTMLElement | undefined = this.$refs.paintings?.$vnode.elm
    const mainPageEl: HTMLElement = this.$refs.mainPage as HTMLElement
    if (!paintingsEl || !mainPageEl) { return }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) { modalStore.addModal() } else { modalStore.removeModal() }
      })
    }, { threshold: 1 })

    observer.observe(paintingsEl)

    await this.$nextTick()

    window.scroll({ top: 0 })
  },
  unMounted () {
    // @ts-ignore

  },

  methods: {
    safariScrollTop () {
      window.scrollTo({ top: 0 })
    },
    toGalleryLinkHandler () {
      // @ts-ignore
      this.displayGallery = true

      // @ts-ignore
    },
    async toPageLinkHandler () {
      // @ts-ignore
      this.displayGallery = false

      await this.$nextTick();

      (this.$refs.page as HTMLElement).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
